import React, { useState, useEffect } from "react";
import "./landing.css";
import hero from "../assets/hero.webp";
import about from "../assets/about.webp";
import buyer from "../assets/buyers.webp";
import merchants from "../assets/merchants.webp";
import {
  PiNumberOneBold,
  PiNumberTwoBold,
  PiNumberThreeBold,
  PiNumberFourBold,
} from "react-icons/pi";
import { BsApple, BsGooglePlay } from "react-icons/bs";
import AOS from 'aos'
import 'aos/dist/aos.css'
const Landing = () => {
  useEffect(() => {
    AOS.init()
  })
  const [isBuyer, setIsBuyer] = useState(true);
  return (
    <>
      <section id="hero" className="hero">
        <div className="flex flex-col px-10 md:p-0 md:gap-20 md:justify-between md:flex-row items-center md:pl-28">
          <div data-aos="fade-right" className="flex flex-col gap-5 my-48">
            <div className="flex flex-col gap-5 text-left md:text-left">
              <h1 className="text-white text-4xl md:text-6xl font-medium mb-5 mt-[-5rem] md:mt-0">
                Need something? <br />{" "}
                <span className="text-[#FF2A00] ">We have it.</span>
              </h1>
              <p className="text-white text-lg md:pr-32 md:mb-10">
              QuickShop is a marketplace to connect individuals, businesses,
                and brands all across Africa.
              </p>
            </div>
            <div className="flex flex-col md:flex-row gap-4 md:gap-10 ">
              <a href="https://apps.apple.com/ng/app/the-owlet/id1578904643" className="bg-[#262728] text-white rounded-md flex flex-row gap-5 p-5 justify-center items-center">
                {" "}
                <span>
                  <BsApple />
                </span>{" "}
                <span>
                  Download on the <br></br>{" "}
                  <span className="text-xl">App Store</span>
                </span>
              </a> 
              <a href="https://play.google.com/store/apps/details?id=com.app.owlet" className="bg-[#262728] text-white rounded-md flex flex-row gap-5 p-5 justify-center items-center px-[1.8rem]">
                {" "}
                <span>
                  <BsGooglePlay />
                </span>{" "}
                <span>
                  Get it on <br></br>{" "}
                  <span className="text-xl">Google Play</span>
                </span>
              </a>
            </div>
          </div>
          <div data-aos="fade-left" className=" md:mt-28 flex items-center justify-center ">
            <img src={hero} alt="" className=" " />
          </div>
        </div>
      </section>
      <section id="merchant" className="about">
        <div className="flex flex-col px-8 md:p-0 md:gap-20 md:flex md:justify-between md:flex-row items-center md:pl-28">
          <div className="flex flex-col gap-5 mt-28">
            <div className="flex flex-col gap-5 text-left md:text-left">
              <h2 data-aos="fade-up" className="text-black text-4xl md:text-6xl font-medium">
                Make extra money on {" "}
                <span className="text-[#FF2A00] ">QuickShop</span>
              </h2>
              <p data-aos="fade-up" className="text-black md:pr-32  md:mb-10">
                Sell your products easily and manage orders with our specially
                curated system.
              </p>
            </div>
            <div data-aos="fade-up" className="flex flex-col md:flex-row gap-4 md:gap-10 ">
              <a href="https://apps.apple.com/ng/app/the-owlet/id1578904643" className="bg-[#000] text-white rounded-md flex flex-row gap-5 p-5 justify-center items-center">
                {" "}
                <span>
                  <BsApple />
                </span>{" "}
                <span>
                  Download on the <br></br>{" "}
                  <span className="text-xl">App Store</span>
                </span>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.app.vendor&hl=en&gl=US" className="bg-[#000] text-white rounded-md flex flex-row gap-5 p-5 justify-center items-center px-[1.8rem]">
                {" "}
                <span>
                  <BsGooglePlay />
                </span>{" "}
                <span>
                  Get it on <br></br>{" "}
                  <span className="text-xl">Google Play</span>
                </span>
              </a>
            </div>
          </div>
          <div data-aos="fade-up" className="mt-28 md:mt-28 flex items-center justify-center ">
            <img src={about} alt="" className="" />
          </div>
        </div>
      </section>
      <section  className="marketplace">
        <div data-aos="zoom-in" className="marketplace-bg">
          <h3 className="text-3xl md:text-4xl font-medium text-white">
            We are bringing the marketplace to you.
          </h3>
          <p className="text-white ">
          QuickShop brings sellers one step closer to buyers; browse through our
            extensive marketplace for a seamless blend of quality and
            affordability. We collaborate with trusted sellers and artisans from
            around the world, ensuring that each item meets our stringent
            standards.
          </p>
        </div>
      </section>
      <section id="how-it-works" className="how-it-works">
        <div>
          <h3 data-aos="fade-up" className="text-4xl text-black mt-[10rem] font-semibold">How it works</h3>
          <p data-aos="fade-up" className="text-black">Get to know more about QuickShop</p>
        </div>
        <div data-aos="fade-up" className="flex flex-col items-center justify-center mt-[3rem]">
          <div className="relative inline-flex w-[40vh] md:w-[30vw] bg-gray-300 rounded shadow-[inset 20px -20px 60px #bebebe, inset -20px 20px 60px #ffffff] mb-5 rounded-lg py-3">
            <span
              className="inline-block w-1/2 px-3 py-2 text-center text-black transition-all duration-300 ease-in-out cursor-pointer"
              onClick={() => setIsBuyer(true)}
            >
              For Buyers
            </span>
            <span
              className="inline-block w-1/2 px-3 py-2 text-center text-black transition-all duration-300 ease-in-out cursor-pointer"
              onClick={() => setIsBuyer(false)}
            >
              For Merchants
            </span>
            <span
              className={`absolute top-0 h-full w-1/2 transition-transform duration-300 ease-in-out rounded-lg ${
                isBuyer
                  ? "bg-black text-white transform translate-x-0 flex justify-center items-center"
                  : "bg-black text-white  transform translate-x-full flex justify-center items-center"
              }`}
            >
              {" "}
              {isBuyer ? "For Buyers" : "For Merchants"}
            </span>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center px-20 py-10 gap-20 md:gap-40">
            <div>
              {isBuyer ? (
                <img src={buyer} alt="" />
              ) : (
                <img src={merchants} alt="" />
              )}
            </div>
            <div className="flex flex-col justify-center items-center gap-6">
              {isBuyer ? (
                <div className="flex flex-col text-left gap-5">
                  <div className="flex flex-row  text-left gap-5">
                    <span>
                      <PiNumberOneBold className="text-white rounded-full p-2 text-3xl bg-[#FF2A00]" />
                    </span>{" "}
                    <span>Download QuickShop</span>
                  </div>
                  <div className="flex flex-row  text-left gap-5">
                    <span>
                      <PiNumberTwoBold className="text-white rounded-full p-2 text-3xl bg-[#FF2A00]" />
                    </span>{" "}
                    <span>Create your account</span>
                  </div>
                  <div className="flex flex-row  text-left gap-5">
                    <span>
                      <PiNumberThreeBold className="text-white rounded-full p-2 text-3xl bg-[#FF2A00]" />
                    </span>{" "}
                    <span>Start buying everything you need</span>
                  </div>
                  <div className="flex flex-col md:flex-row gap-4 md:gap-10 ">
                <a href="https://apps.apple.com/ng/app/the-owlet/id1578904643" className="bg-[#000] text-white rounded-md flex flex-row gap-5 p-5 justify-center items-center">
                  {" "}
                  <span>
                    <BsApple />
                  </span>{" "}
                  <span>
                    Download on the <br></br>{" "}
                    <span className="text-l">App Store</span>
                  </span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.app.owlet" className="bg-[#000] text-white rounded-md flex flex-row gap-5 p-5 justify-center items-center px-[1.8rem]">
                  {" "}
                  <span>
                    <BsGooglePlay />
                  </span>{" "}
                  <span>
                    Get it on <br></br>{" "}
                    <span className="text-l">Google Play</span>
                  </span>
                </a>
              </div>
                </div>
              ) : (
                <div className="flex flex-col text-left  gap-5">
                  <div className="flex flex-row text-left gap-5">
                    <span>
                      <PiNumberOneBold className="text-white rounded-full p-2 text-3xl bg-[#FF2A00]" />
                    </span>{" "}
                    <span>Download QuickShop</span>
                  </div>
                  <div className="flex flex-row text-left gap-5">
                    <span>
                      <PiNumberTwoBold className="text-white rounded-full p-2 text-3xl bg-[#FF2A00]" />
                    </span>{" "}
                    <span>Create your account</span>
                  </div>
                  <div className="flex flex-row text-left gap-5">
                    <span>
                      <PiNumberThreeBold className="text-white rounded-full p-2 text-3xl bg-[#FF2A00]" />
                    </span>{" "}
                    <span>Provide the necessary KYC information</span>
                  </div>
                  <div className="flex flex-row text-left gap-5">
                    <span>
                      <PiNumberFourBold className="text-white rounded-full p-2 text-3xl bg-[#FF2A00]" />
                    </span>{" "}
                    <span>Add products, set up shipping and start selling</span>
                  </div>
                  <div className="flex flex-col md:flex-row gap-4 md:gap-10 ">
                <a href="https://apps.apple.com/ng/app/the-owlet/id1578904643" className="bg-[#000] text-white rounded-md flex flex-row gap-5 p-5 justify-center items-center">
                  {" "}
                  <span>
                    <BsApple />
                  </span>{" "}
                  <span>
                    Download on the <br></br>{" "}
                    <span className="text-l">App Store</span>
                  </span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.app.vendor&hl=en&gl=US" className="bg-[#000] text-white rounded-md flex flex-row gap-5 p-5 justify-center items-center px-[1.8rem]">
                  {" "}
                  <span>
                    <BsGooglePlay />
                  </span>{" "}
                  <span>
                    Get it on <br></br>{" "}
                    <span className="text-l">Google Play</span>
                  </span>
                </a>
              </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Landing;
