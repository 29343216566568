import React, {useState, useEffect} from "react";
import './App.css'
import CookieConsent from 'react-cookie-consent'
import {BsArrowUpShort} from 'react-icons/bs'
import NavBar from "./Navbar/NavBar";
import Landing from "./Landing/Landing";
import Footer from "./Footer/Footer";
function App() {
  const [progress, setProgress] = useState(0);
   const [scrollDirection, setScrollDirection] = useState("down");

   useEffect(() => {
     const handleScroll = () => {
       const scrollTop = window.pageYOffset;
       const windowHeight = window.innerHeight;
       const documentHeight = document.body.scrollHeight;
       const maxScrollTop = documentHeight - windowHeight;
       const currentProgress = scrollTop / maxScrollTop;

       if (currentProgress < progress && scrollDirection === "down") {
         setScrollDirection("up");
       } else if (currentProgress > progress && scrollDirection === "up") {
         setScrollDirection("down");
       }
       setProgress(currentProgress);
     };

     window.addEventListener("scroll", handleScroll);

     return () => window.removeEventListener("scroll", handleScroll);
   }, [progress, scrollDirection]);

   const fillStyle = {
     height: `${progress * 100}%`,
     background: "#FF2A00",
     borderRadius: "10%",
     transition: "height 0.2s ease-in-out",
   };
  return (
    <>
    <div className="navigator">
    <a href="#hero">
      <div className="progress-bar">
      <button 
         >
        <BsArrowUpShort className='navigator-btn' />
      </button>
        <div className="fill" 
         style={fillStyle}
         />
      </div>
      </a>
    </div>
    
      <NavBar />
      <Landing />
      <Footer />
      {/* <CookieConsent debug={true}
      style={{color:"#ffffff", textAlign: 'left'}}
      buttonStyle={{color:"#000", backgroundColor:"#ffffff"}}
      buttonText= "Allow all cookies"
      expires={200}
      >
        This website collects cookies to deliver better user experience
      </CookieConsent> */}
    </>
  );
}

export default App;
