import React from "react";
import logo from "../assets/logo.webp";
import { HiOutlineMail, HiOutlineLocationMarker } from "react-icons/hi";
import { BiLogoFacebookSquare, BiLogoTwitter, BiLogoYoutube } from "react-icons/bi";
import { RiInstagramFill } from "react-icons/ri";
import "./footer.css";
const Footer = () => {
  return (
    <>
      <footer className="bg-black w-full h-full flex flex-col justify-center items-center mt-[2rem] py-20 px-10">
        <div className="">
          <h3 className="text-white text-center text-lg font-bold ">
            Subscribe to our Newsletter
          </h3>
          <p className="text-white text-center">
            Be the first to recieve update about owlet community and friends
          </p>
          <form action="" className="flex justify-center items-center mt-5">
            <div className="input-container">
              <input
                required=""
                placeholder="Enter your email address"
                type="email"
              />
              <button className="invite-btn" type="button">
                Subscribe
              </button>
            </div>
          </form>
          <div className="circle"></div>
        </div>
        <div className="flex flex-col justify-center items-center mt-32 gap-5 ">
          <img src={logo} alt="" className="w-[35%]"/>
          <div className="flex flex-row items-center justify-center gap-3">
            <span>
              {" "}
              <HiOutlineMail className="text-white" />{" "}
            </span>{" "}
            <a href="mailto:support@getquickshop.com" className="text-white">
            Support@getquickshop.com
            </a>
          </div>
          <div className="flex flex-row items-center justify-center gap-3 text-center px-10">
            <span>
              {" "}
              <HiOutlineLocationMarker className="text-white" />{" "}
            </span>{" "}
            <span className="text-white">
            Plot 162A, Independence Layout, Enugu, Enugu State.
            </span>
          </div>
          <div className="flex flex-row gap-16 mt-5 justify-center items-center">
            <a href="https://web.facebook.com/profile.php?id=100082913933205">
              <BiLogoFacebookSquare className="bg-[#262728] text-white text-3xl p-2 rounded-full" />
            </a>
            <a href="https://twitter.com/owletapp">
              <BiLogoTwitter className="bg-[#262728] text-white text-3xl p-2 rounded-full" />
            </a>
            <a
              href="https://www.instagram.com/owletapp/"
            >
              {" "}
              <RiInstagramFill className="bg-[#262728] text-white text-3xl p-2 rounded-full" />
            </a>
            <a href="https://www.youtube.com/@Theowletonline"> <BiLogoYoutube className="bg-[#262728] text-white text-3xl p-2 rounded-full"/></a>
          </div>
        </div>
        <div className="mt-20 text-center px-12">
            <span className="text-white  ">Copyright &copy; QuickShop 2023. All rights reserved</span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
