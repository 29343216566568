import React, { useState, useEffect, Fragment } from "react";
import logo from "../assets/logo.webp";
import { Dialog, Transition } from "@headlessui/react";
import Collapsible from "react-collapsible";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isPolicy, setIsPolicy] = useState(true);

  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setIsScrolled(isScrolled);
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backgroundColor = isScrolled
    ? "bg-black bg-opacity-100 backdrop-blur-sm"
    : "bg-transparent";

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
  
    const closeMenu = () => {
      setMenuOpen(false);
    };
  return (
    <>
      <header
        className={"overflow-hidden fixed w-full z-10 "}
        //    className= 'w-full z-10 shadow fixed bg-opacity-10 border border-1 border-transparent backdrop-blur-lg'>
      >

<nav className= "bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="p-0 md:p-10">
            <a href="/" >
            <img src={logo} className="w-[30%] sm:w-[40%] md:w-[30%]" alt="" />
            </a>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4 whitespace-nowrap">
              <a
                href="#hero"
                className="text-gray-300 hover:bg-gray-300 hover:text-[#FF2A00] px-3 py-2 rounded-md text-sm font-medium"
                onClick={closeMenu}
              >
               Quickshop
              </a>
              <a
                href="#merchant"
                className="text-gray-300 hover:bg-gray-300 hover:text-[#FF2A00] px-3 py-2 rounded-md text-sm font-medium"
                onClick={closeMenu}
              >
                Merchant
              </a>
              <a
                 href="#how-it-works"
                className="text-gray-300 hover:bg-gray-300 hover:text-[#FF2A00] px-3 py-2 rounded-md text-sm font-medium"
                onClick={closeMenu}
              >
                How it works
              </a>
              <a
                href="#terms"
                className="text-gray-300 hover:bg-gray-300 hover:text-[#FF2A00] px-3 py-2 rounded-md text-sm font-medium"
                // onClick={closeMenu}
                onClick={toggleDialog}
              >
                Terms & Condition
              </a>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              className="bg-black inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-[#FF2A00] hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${menuOpen ? 'hidden' : 'block'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${menuOpen ? 'block' : 'hidden'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          menuOpen ? 'block' : 'hidden'
        } md:hidden animate-slide-down`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <a
           href="#hero"
            className="text-gray-300 hover:bg-gray-300 hover:text-[#FF2A00] block px-3 py-2 rounded-md text-base font-medium"
            onClick={closeMenu}
          >
            Quickshop
          </a>
          <a
            href="#merchant"
            className="text-gray-300 hover:bg-gray-300 hover:text-[#FF2A00] block px-3 py-2 rounded-md text-base font-medium"
            onClick={closeMenu}
          >
            Merchant
          </a>
          <a
             href="#how-it-works"
            className="text-gray-300 hover:bg-gray-300 hover:text-[#FF2A00] block px-3 py-2 rounded-md text-base font-medium"
            onClick={closeMenu}
          >
           How it works
          </a>
          <a
            href="#terms"
            className="text-gray-300 hover:bg-gray-300 hover:text-[#FF2A00] block px-3 py-2 rounded-md text-base font-medium"
            // onClick={closeMenu}
            onClick={toggleDialog}
          >
            Terms & Condition
          </a>
        </div>
      </div>
    </nav>
        {/* <nav className="container mx-auto px-2 py-6 md:py-3 ">
          <div className="flex flex-col md:justify-between md:flex-row md:gap-20 md:items-center">
            <div className="flex flex-row-reverse justify-between md:grid md:grid-cols-2 md:items-center">
              <div>
                <a href="/" className="">
                  <img src={logo} className="ml-28 w-[60%]" alt="" />
                </a>
              </div>
              <div className="flex md:hidden">
                <button
                  type="button"
                  className="text-[#FF2A00]"
                  aria-label="toggle menu"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  {isMenuOpen ? (
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 5h16a1 1 0 010 2H4a1 1 0 110-2zm0 6h16a1 1 0 010 2H4a1 1 0 110-2zm0 6h16a1 1 0 010 2H4a1 1 0 110-2z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div> */}

            {/* <div
              className={`bg-black h-full w-[100%]  bg-opacity-80  md:bg-opacity-100 border border-1 backdrop-blur-lg  fixed top-16 z-50 rounded-lg left-[-1rem] p-10 pb-28 flex flex-col py-8 gap-8 md:border-none md:static md:top-3 md:bg-transparent md:pb-12 md:h-0 md:flex items-center ${
                isMenuOpen ? "" : "hidden"
              }`}
            > */}
            {/* <div
              className={`overflow-hidden fixed top-20 bg-red-700 w-full z-50 ${
                isMenuOpen ? "" : "hidden"
              }`}
            >
              <div className="flex flex-col gap-3 md:flex-row md:mx-6 md:gap-10 whitespace-nowrap">
                <a
                  href="#hero"
                  className="my-1 text-white font-medium text-sm  hover:text-[#FF2A00] md:mx-4 md:my-0"
                >
                  QuickShop
                </a>
                <a
                  href="#merchant"
                  className="my-1 text-white font-medium text-sm hover:text-[#FF2A00] md:mx-4 md:my-0"
                >
                  Merchant
                </a>
                <a
                  href="#how-it-works"
                  className="my-1 text-white font-medium text-sm hover:text-[#FF2A00] md:mx-4 md:my-0"
                >
                  How it works
                </a>
                <a
                  href="#terms"
                  className="my-1 text-white font-medium text-sm hover:text-[#FF2A00] md:mx-4 md:my-0"
                  onClick={toggleDialog}
                >
                  Terms & Condition
                </a>
              </div>
            </div>
          </div>
        </nav> */}

        <Transition.Root show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto "
            onClose={toggleDialog}
          >
            <div className="flex items-center justify-center min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-70 backdrop-blur-lg " />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block align-bottom  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-[80vw] ">
                  <div className="bg-white  px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <div className="absolute top-0 right-0 p-4">
                          <button
                            type="button"
                            className=" bg-gray-400 hover:bg-[#FF2A00] text-white rounded-full p-1"
                            onClick={() => setIsOpen(false)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          <div className="relative inline-flex w-[40vh] md:w-[30vw] my-5 ">
                            <span
                              className="inline-block w-1/2 px-3 text-sm md:text-lg py-2 text-center text-black transition-all duration-300 ease-in-out cursor-pointer"
                              onClick={() => setIsPolicy(true)}
                            >
                              Privacy Policy
                            </span>
                            <span
                              className="inline-block w-1/2 px-3 py-2 text-sm md:text-lg text-center text-black transition-all duration-300 ease-in-out cursor-pointer"
                              onClick={() => setIsPolicy(false)}
                            >
                              Terms of Service
                            </span>
                            <span
                              className={`absolute top-0 h-full w-1/2 transition-transform duration-300 ease-in-out  ${
                                isPolicy
                                  ? "  border-2 border-[#FF2A00] border-t-0 border-l-0 border-r-0  text-black transform translate-x-0 flex justify-center items-center"
                                  : "  border-2 border-[#FF2A00] border-t-0 border-l-0 border-r-0 text-black  transform translate-x-full flex justify-center items-center"
                              }`}
                            >
                              {" "}
                              {isPolicy ? " " : " "}
                            </span>
                          </div>
                        </Dialog.Title>
                        <div className="mt-4 space-y-4">
                          {isPolicy ? (
                            <>
                              <div>
                                <h2 className="font-extrabold text-2xl ">
                                QuickShop Privacy Policy
                                </h2>
                                <small>Effective Date: 18/06/2023</small>
                                <p className="font-thin text-sm my-5">
                                  This Privacy Policy describes how the QuickShop
                                  ("QuickShop," "we," "us," or "our") collects,
                                  uses, and discloses personal information when
                                  you use our User's App and Merchant App
                                  (collectively referred to as the "QuickShop").
                                  The QuickShop is designed to facilitate
                                  connections between buyers and sellers, but
                                  does not involve itself in the trades or
                                  transactions conducted between them. We are
                                  committed to protecting your privacy and
                                  ensuring the security of your personal
                                  information. By using the App, you consent to
                                  the practices described in this Privacy
                                  Policy.
                                </p>
                              </div>

                              <div className="w-full my-10">
                                <Collapsible
                                  className="flex flex-col gap-4  "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>1. Information we collect</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>1. Information we collect</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className="text-left">
                                    1.1 Personal Information: When you use the
                                    QuickShop, we may collect personal
                                    information from you, such as:{" "}
                                  </span>
                                  <ul className="px-6">
                                    <li className="flex flex-row gap-1 text-left">
                                      <GoDotFill />
                                      User App: Your name, email address,
                                      username, profile picture, shipping
                                      address, payment information (if
                                      applicable), and any other information you
                                      provide when creating an account or using
                                      the features of the User's App.
                                    </li>
                                    <li className="flex flex-row gap-1 text-left">
                                      <GoDotFill />
                                      Merchant App: Your name, email address,
                                      business name, business address, payment
                                      information (if applicable), and any other
                                      information you provide when creating an
                                      account or using the features of the
                                      Merchant App.
                                    </li>
                                  </ul>
                                  <span className="text-left">
                                    1.2 Usage Information: We may also collect
                                    certain information automatically when you
                                    use the App, such as your IP address, device
                                    type, operating system, unique device
                                    identifiers, and other technical information
                                    about your use of the App.
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>2. Use of information</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>2. Use of information</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    2.1  Personal Information: We may use the
                                    personal information we collect for the
                                    following purposes:{" "}
                                  </span>
                                  <ul className="px-6 text-left">
                                    <li className="flex flex-row text-left gap-1 ">
                                      <GoDotFill className="text-l" />
                                      To create and maintain your account.
                                    </li>
                                    <li className="flex flex-row gap-1 text-left">
                                      <GoDotFill className="text-l" />
                                      To provide you with access to and use of
                                      the features of the App.
                                    </li>
                                    <li className="flex flex-row gap-1 text-left">
                                      <GoDotFill className="text-l" />
                                      To communicate with you, respond to your
                                      inquiries, and provide customer support.
                                    </li>
                                    <li className="flex flex-row gap-1 text-left">
                                      <GoDotFill />
                                      To personalize and improve your experience
                                      with the App.
                                    </li>
                                    <li className="flex flex-row gap-1 text-left">
                                      <GoDotFill />
                                      To send you updates, newsletters,
                                      promotional materials, and other
                                      information related to the App, subject to
                                      your preferences and applicable laws.
                                    </li>
                                    <li className="flex flex-row gap-1 text-left">
                                      <GoDotFill />
                                      To enforce our Terms of Service and other
                                      policies.
                                    </li>
                                    <li className="flex flex-row gap-1 text-left">
                                      <GoDotFill />
                                      To comply with legal obligations and
                                      assist law enforcement agencies, if
                                      necessary.
                                    </li>
                                  </ul>
                                  <span className=" text-left">
                                    2.2 Usage Information: We may use the usage
                                    information we collect to analyze trends,
                                    administer the App, track users' movements,
                                    and gather demographic information for
                                    aggregate use. This information helps us
                                    understand how users interact with the App
                                    and improve its functionality and
                                    performance.
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>3. Information sharing</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>3. Information sharing</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <div className="flex flex-col">
                                    <span className=" text-left">
                                      3.1  Third-Party Service Providers: We may
                                      share your personal information with
                                      third-party service providers that assist
                                      us in operating and maintaining the App,
                                      such as hosting providers, payment
                                      processors, customer support services, and
                                      analytics providers. These service
                                      providers will have access to your
                                      personal information only to the extent
                                      necessary to perform their functions and
                                      are obligated to protect it.{" "}
                                    </span>
                                    <span className=" text-left">
                                      3.2 Legal Requirements: We may disclose
                                      your personal information if required by
                                      law, regulation, legal process, or
                                      governmental request, or to protect our
                                      rights, property, or safety, or the
                                      rights, property, or safety of our users
                                      or others.
                                    </span>
                                    <span className=" text-left">
                                      3.3 Business Transfers: In the event of a
                                      merger, acquisition, or sale of all or a
                                      portion of our assets, your personal
                                      information may be transferred to the
                                      acquiring entity or third party involved
                                      in such transaction.
                                    </span>
                                  </div>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>4. Security</h2> <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>4. Security</h2> <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    We take reasonable measures to protect the
                                    personal information we collect through the
                                    App against loss, theft, unauthorized
                                    access, disclosure, alteration, and
                                    destruction. However, no method of
                                    transmission over the internet or electronic
                                    storage is entirely secure. Therefore, we
                                    strive to use commercially acceptable means
                                    to protect your personal information.{" "}
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>5. Third-Party Links</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>5. Third-Party Links</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    The App may contain links to third-party
                                    websites, products, or services. We are not
                                    responsible for the privacy practices of
                                    these third parties, and you should review
                                    their privacy policies before providing any
                                    personal information to them.
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>6. Changes to this Privacy Policy</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>6. Changes to this Privacy Policy</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    We may update this Privacy Policy from time
                                    to time. If we make material changes to this
                                    Privacy Policy, we will notify you by email
                                    or by posting a prominent notice on the App.
                                    Your continued use of the App after such
                                    modifications will constitute your
                                    acknowledgment of the modified Privacy
                                    Policy.{" "}
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>7. Contact Us</h2> <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>7. Contact Us</h2> <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    If you have any questions or concerns about
                                    this Privacy Policy or our privacy
                                    practices, please contact us at{" "}
                                    <span className="text-[#FF2A00]">
                                      support@getquickshop.com.
                                    </span>{" "}
                                    By using the App, you acknowledge that you
                                    have read and understood this Privacy Policy
                                    and agree to the collection, use, and
                                    disclosure of your personal information as
                                    described herein.
                                  </span>
                                </Collapsible>
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <h2 className="font-extrabold text-2xl ">
                                QuickShop Terms of Service
                                </h2>
                                <small>Effective Date: 18/06/2023</small>
                                <p className="font-thin text-sm my-5">
                                  These Terms of Service ("Terms") govern your
                                  use of the marketplace application
                                  ("QuickShop") provided by THE-OWLET HOLDINGS
                                  LLC ("we," "us," or "our"). By accessing or
                                  using the App, you agree to be bound by these
                                  Terms. Please read these Terms carefully
                                  before using the App.
                                </p>
                              </div>

                              <div className="w-full my-10">
                                <Collapsible
                                  className="flex flex-col gap-4  "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>1. Acceptance of Terms</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>1. Acceptance of Terms</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className="text-left">
                                    By accessing or using the App, you
                                    acknowledge that you have read, understood,
                                    and agree to be bound by these Terms,
                                    including our Privacy Policy. If you do not
                                    agree with these Terms or our Privacy
                                    Policy, you should not use the App.{" "}
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>2. Description of the App</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>2. Description of the App</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    The App is a marketplace platform that
                                    provides services to users ("Users") and
                                    merchants ("Merchants"). The App facilitates
                                    the connection between Users and Merchants,
                                    allowing Users to browse and purchase goods
                                    or services offered by Merchants. The App
                                    acts solely as a platform and does not
                                    involve itself in the actual transactions
                                    between Users and Merchants.{" "}
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>3. User and Merchant Accounts</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>3. User and Merchant Accounts</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <div className="flex flex-col">
                                    <span className=" text-left">
                                      3.1 User Accounts: To use certain features
                                      of the App, Users may be required to
                                      create an account. Users must provide
                                      accurate and complete information during
                                      the registration process and keep their
                                      account information up to date. Users are
                                      responsible for maintaining the
                                      confidentiality of their account
                                      credentials and are solely responsible for
                                      all activities that occur under their
                                      account.
                                    </span>
                                    <span className=" text-left">
                                      3.2 Merchant Accounts: Merchants may be
                                      required to create an account and provide
                                      certain information about their business
                                      to get approved. Merchants must ensure
                                      that the information provided is accurate
                                      and up to date. Merchants are responsible
                                      for managing their account, including the
                                      products or services they offer,
                                      inventory, pricing, and fulfillment.
                                    </span>
                                  </div>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>4. Use of the App</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>4. Security</h2> <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <div className="flex flex-col">
                                  <span className=" text-left">
                                    4.1  User Responsibilities: Users agree to
                                    use the App in compliance with all
                                    applicable laws, regulations, and these
                                    Terms. Users are solely responsible for
                                    their interactions and transactions with
                                    Merchants. Users acknowledge that any
                                    purchases made through the App are between
                                    the User and the Merchant, and the App is
                                    not a party to such transactions.
                                  </span>
                                  <span className=" text-left">
                                    4.2 Merchant Responsibilities: Merchants
                                    agree to use the App in compliance with all
                                    applicable laws, regulations, and these
                                    Terms. Merchants are solely responsible for
                                    the goods or services they offer, including
                                    but not limited to quality, accuracy, and
                                    legality. Merchants are responsible for
                                    providing accurate and complete information
                                    about their products or services, including
                                    pricing, availability, and shipping options.
                                  </span>
                                  </div>
                                 
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>5. Intellectual Property</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>5. Intellectual Property</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    The App and all its contents, including but
                                    not limited to text, graphics, logos,
                                    trademarks, and software, are owned by or
                                    licensed to us and are protected by
                                    intellectual property laws. Users and
                                    Merchants agree not to modify, reproduce,
                                    distribute, or create derivative works based
                                    on the App or its content without our prior
                                    written consent.
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>6. Indemnification</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>6. Indemnification</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    To the fullest extent permitted by law,
                                    Users and Merchants agree to indemnify and
                                    hold us harmless from any claims, losses,
                                    damages, liabilities, and expenses
                                    (including attorney's fees) arising out of
                                    their use of the App, violation of these
                                    Terms, or infringement of any third-party
                                    rights.
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>7. Limitation of Liability</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>7. Limitation of Liability</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    To the fullest extent permitted by law, we
                                    shall not be liable for any indirect,
                                    incidental, special, consequential, or
                                    punitive damages arising out of or in
                                    connection with the use of the App, even if
                                    we have been advised of the possibility of
                                    such damages. Our total aggregate liability
                                    for any claim arising out of or relating to
                                    these Terms or the App shall not exceed the
                                    fees paid by the User or Merchant, if
                                    applicable, in the twelve (12) months
                                    preceding the claim.
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>8. Termination</h2> <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>8. Termination</h2> <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    We reserve the right to suspend or terminate
                                    your access to the App at any time and for
                                    any reason without prior notice. Upon
                                    termination, your right to use the App will
                                    cease immediately, and you must cease all
                                    use of the App.
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>9. Modifications to the Terms</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>9. Modifications to the Terms</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    We may modify these Terms at any time by
                                    posting the revised version on the App. The
                                    modified Terms will become effective upon
                                    posting. By continuing to use the App after
                                    the modifications have been made, you agree
                                    to be bound by the revised Terms.
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>
                                        10. Governing Law and Dispute Resolution
                                      </h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>
                                        10. Governing Law and Dispute Resolution
                                      </h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    These Terms shall be governed by and
                                    construed in accordance with the laws of the
                                    Federal Republic of Nigeria. Any dispute
                                    arising out of or in connection with these
                                    Terms shall be submitted to binding
                                    arbitration in accordance with the rules of
                                    QuickShop or resolved in the courts located
                                    in Nigeria.
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>11. Severability</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>11. Severability</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    If any provision of these Terms is held to
                                    be invalid, illegal, or unenforceable, the
                                    validity, legality, and enforceability of
                                    the remaining provisions shall not be
                                    affected or impaired.
                                  </span>
                                </Collapsible>
                                <Collapsible
                                  className="flex flex-col gap-4 my-3 "
                                  trigger={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>12. Entire Agreement</h2>{" "}
                                      <BsChevronRight />{" "}
                                    </div>,
                                  ]}
                                  triggerWhenOpen={[
                                    <div className="p-3 rounded-lg flex justify-between items-center cursor-pointer bg-black text-white">
                                      <h2>12. Entire Agreement</h2>{" "}
                                      <BsChevronDown />{" "}
                                    </div>,
                                  ]}
                                >
                                  <span className=" text-left">
                                    These Terms, together with our Privacy
                                    Policy, constitute the entire agreement
                                    between you and us regarding the use of the
                                    App and supersede any prior or
                                    contemporaneous understandings or
                                    agreements. If you have any questions or
                                    concerns regarding these Terms, please
                                    contact us at <span className="text-[#FF2A00]"> support@getquickshop.com </span>{" "}
                                  </span>
                                </Collapsible>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </header>
    </>
  );
};

export default NavBar;
